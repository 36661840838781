<template>
  <div class="imgBox">
    <div class="imgBox1 max-w">
      <div class="img1 max-w">
        <!-- <img :src="productImg1" :alt="altText" class="imgItem" /> -->
      </div>
    </div>
    <div class="imgBox2">
      <div class="img2">
        <img :src="productImg2" :alt="altText" class="imgItem2" />
      </div>
    </div>
    <div class="imgBox3 min-w-1400">
      <div class="img3 max-w-1400">
        <img :src="productImg3" :alt="altText" class="imgItem3" />
      </div>
    </div>
  </div>
</template>

<script>
import productImg2 from "@/page/ngernsaen2/components/img/4_2.png";
import productImg3 from "@/page/ngernsaen2/components/img/footer.png";
export default {
  data() {
    return {
      productImg2: productImg2,
      productImg3: productImg3,
      altText: "图片",
    };
  },
    created() {
    this.gotoTop();
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

 
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background-color: #fafcff;
  min-width: 1400px;
}
.imgBox1{
  margin-top: 130px;
}
.imgBox1 {
  width: 100%;
  min-width: 1400px;
  height: 250px;
  background-color: #206AC4;
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  min-width: 1400px;
  height: 3200px;
  background-color: #ffffff;
  border-radius: 32px;
  margin-top: -130px;
  margin-left: auto;
  margin-right: auto;
}
.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
  max-width: 1620px;
}
.img2

.img4 {
  max-width: 1620px;
  overflow: hidden;
}
.img3{
    width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1,.imgItem3 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  margin-top: 60px;
}
</style>
