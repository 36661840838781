<template>
  <nav class="navbar min-w-1400">
    <div class="secondBox max-w-1400">
      <div class="logo">
        <!-- <img
          :src="require('@/page/ceibamóvil/components/img/logo.png')"
          :alt="logo"
          class="logoImg"
        /> -->
        <span class="title">NGERN SAEN SABAI COMPANY LIMITED</span>
      </div>
      <ul class="nav-links">
        <li :class="{ 'active-link': isActive('/') }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">เกี่ยวกับบริษัท</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/about' }" class="Li2">
          <router-link to="/about" class="nav-link2">
            <span class="routeText2">สินเชื่อและบริการ</span>
          </router-link>
        </li>
        <li
          :class="{ 'active-link': $route.path === '/questions' }"
          class="Li3"
        >
          <router-link to="/questions" class="nav-link3">
            <span class="routeText3">ปัญหาทั่วไป</span>
          </router-link>
        </li>
        <!-- <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li4">
          <router-link to="/protocol" class="nav-link4">
            <span class="routeText4">Chính sách bảo mật</span>
          </router-link>
        </li> -->
        <li
          class="dropdown Li4"
          :class="{ 'active-link': $route.path === '/protocol' }"
        >
          <!-- 添加下拉图标 -->
          <router-link to="/protocol" class="nav-link4">
            <span class="dropdown-toggle routeText4">ข้อตกลงความเป็นส่วนตัว</span>
          </router-link>
          <img
            :src="
              require(isShow
                ? '@/page/onsenmobile/components/img/img.png'
                : '@/page/onsenmobile/components/img/1.png')
            "
            alt=""
            @click="showOptions"
          />
          <ul class="dropdown-menu" v-if="isShow">
            <li>
              <a
                href="https://tgcaw.ngernsaenloan.com/help/privacy_agreement"
                target="_blank"
                >ข้อตกลงส่วนตัวของ Android</a
              >
            </li>
            <li @click="closeOptions">
              <span  class="text2">ข้อตกลงความเป็นส่วนตัวของ iOS</span>
            </li>
          </ul>
        </li>
        <!-- <li
          :class="{
            'active-link': ['/questions', '/protocol'].includes($route.path),
          }"
          class="Li4"
        >
          <router-link to="/questions" class="nav-link4">
            <span class="routeText4">Vấn đề Thường Gặp</span>
          </router-link>
        </li> -->
        <!-- <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li5">
          <router-link to="/protocol" class="nav-link5">
            <span class="routeText5">Acuerdo de privacidad</span>
          </router-link>
        </li> -->
      </ul>
    </div>
    <div style="height: 130px"></div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      link1Color: "white",
      isShow: false,
    };
  },
  // created() {
  //   this.getUrlParams();
  // },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },

    showOptions() {
      if (this.isShow) {
        this.isShow = false;
      } else {
        this.isShow = true;
      }
    },
    closeOptions(){
      this.isShow = false;
    },
    openPDF() {
      window.open('/Business Registration Certificate.pdf')
    },
    // getUrlParams() {
    //   const url = window.location.href;
    //   let obj = {};
    //   try {
    //     let urlStr = url.split("?")[1];
    //     let paramsArr = urlStr.split("&");
    //     for (let i = 0, len = paramsArr.length; i < len; i++) {
    //       let arr = paramsArr[i].split("=");
    //       obj[arr[0]] = arr[1];
    //     }
    //   } catch (error) {
    //     console.error("An error occurred while parsing URL parameters:", error);
    //   }
    //   const pdfDom = document.getElementById("pdf-iframe");
    //   const docid = document.getElementById("docid");
    //   if (obj) {
    //     docid.textContent = obj.docid || "";
    //     pdfDom.setAttribute(
    //       "src",
    //       `./assets/${(obj.token || "").toLowerCase()}.pdf`
    //     );
    //   }
    // },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  position: sticky; /* 将导航栏固定在页面上 */
  top: 0; /* 位于页面顶部 */
  z-index: 1000; /* 确保导航栏在其他元素上方 */
  height: 80px;
}
.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 80px;
  padding: 0 30px;
  background-color: #ffffff;
  border-bottom: 2px solid #dfe0e3; /* 添加下边框样式 */
}

.logo {
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.logoImg {
  width: 60px;
  height: 60px;
}

.title {
  width: 520px;
  height: 24px;
  font-size: 18px;
  font-family: SF Pro-Black Italic;
  font-weight: 600;
  color: #000000;
  line-height: 23px;
  padding-left: 17px;
  /* text-transform: uppercase; */
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 16px;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #666666;
  line-height: 29px;
  text-transform: uppercase;
}

.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;
  margin: 0 auto;
  text-align: center;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
}

.nav-links li {
  display: flex;
  padding: 8px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 45px;
  padding-left: 4px;
  padding-right: 4px;
}
.Li1 {
  width: 180px;
}
.Li2 {
  width: 150px;
}
.Li3 {
  width: 170px;
}
.Li4 {
  width: 260px;
}
/* .Li5 {
  width: 250px;
} */
/* 这个如果给margin-right底部可能会出现边距的问题 */
/* .Li4 {
  width: 250px;
  
} */

.active-link {
  /* background: linear-gradient(45deg, #F59120, #F95C40); */
  background-color: #206ac4;
  /* border-color: #dae633; */
  border-radius: 10px;
}

.active-link .routeText1,
.active-link .routeText2,
.active-link .routeText3,
.active-link .routeText4,
.active-link .routeText5 {
  font-family: Inter-Extra Bold;

  color: #ffffff;
}

.dropdown {
  position: relative;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 0;
  min-width: 200px;
  width: 260px;
}

.dropdown-menu li {
  padding: 5px 10px;
}

.dropdown-menu li:hover {
  background-color: #ebfff2;
}

.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-menu li a {
  font-size: 13px;
  text-decoration: none;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #206ac4;
  margin-top: 10px;
}
.dropdown-icon {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #206ac4; /* 设置倒三角形的颜色 */
  border-bottom: none; /* 去除下边框 */
  margin-left: 5px;
  vertical-align: middle;
}
a {
  text-transform: uppercase;
}
.text2 {
  text-transform: uppercase;
    color: #206ac4;
     font-size: 13px;
  text-decoration: none;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  padding-top: 10px;
}
/* .routeText4:hover {
  color: #01b540;
} */
</style>